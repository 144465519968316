// import React from 'react';
// import ReactDOM from 'react-dom';
import axios from 'axios';
import { makeUseAxios } from 'axios-hooks';
import { spinningAction } from 'components/LoaderSpinner/actions';
import { getUser } from 'utils/auth';
import { getBaseUrl } from '../business_logic/constants';

const BASE_URL = getBaseUrl();
const axiosApiInstance = axios.create({
  baseURL: BASE_URL,

  // these two values are for playing nice with Django
  xsrfCookieName: 'csrftoken', // name of the cookie to use as a value for xsrf token
  xsrfHeaderName: 'X-CSRFToken', // name of the http header that carries the xsrf token value
});

axiosApiInstance.interceptors.request.use(
  config => {
    // spinning start to show
    console.log('coming here');
    //prettier-ignore
    if (!config.noSpin) { // Pass noSpin=true to the request config to avoid spinner coming up.
      spinningAction(true);
    }

    const user = getUser();
    if (user?.auth_token) {
      config.headers.Authorization = `token ${user.auth_token}`;
    }
    return config;
  },
  error => {
    spinningAction(false);
    return Promise.reject(error);
  },
);
axiosApiInstance.interceptors.response.use(
  response => {
    spinningAction(false);
    return response;
  },
  error => {
    spinningAction(false);
    return Promise.reject(error);
  },
);

export const useAxios = makeUseAxios({
  axios: axiosApiInstance,
});
