import { BASE_URL_LOCAL } from './local_constants';

export const BASE_URL_PRODUCTION = 'https://api.oasis-living.com/';
export const BASE_URL_STAGING = 'https://staging-api.oasis-living.com/';

// Mailchimp audience links
export const MC_WARM_LEAD_AUDIENCE_URL =
  'https://oasis-living.us2.list-manage.com/subscribe/post?u=3b4cf64ea9bad88e2ccef6172&amp;id=2ef4d42e65';
export const MC_HOT_LEAD_AUDIENCE_URL =
  'https://oasis-living.us2.list-manage.com/subscribe/post?u=3b4cf64ea9bad88e2ccef6172&amp;id=8812b6aaa2';

// export const BASE_STRIPE_LIVE_KEY = 'pk_live_AjYCTin971kgnimfvXkyrg0N';
// export const BASE_STRIPE_TEST_KEY = 'pk_test_iH80UR171XU7MH0BceGdBadS';

export const getBaseUrl = () => {
  if (process.env.STAGING === 'TRUE') {
    console.log('Request is happening on Staging env');
    return BASE_URL_STAGING;
  } else if (process.env.NODE_ENV === 'production') {
    return BASE_URL_PRODUCTION;
  }

  return BASE_URL_LOCAL;
};

export const SLACK_MESSAGE_URL = `${getBaseUrl()}api/communications/post-to-slack/`;

// export const getBaseStripeKey = () => {
//   if (process.env.NODE_ENV === 'production') {
//     return BASE_STRIPE_LIVE_KEY;
//   }
//   return BASE_STRIPE_TEST_KEY;
// };

export const UNIFIED_CALL_NUMBER = '+44 20 3514 9023'; // this is not used for now - put in footer
export const TENANT_CALL_NUMBER = '+44 20 3514 9023'; // this is not used for now, put in tenant contact form
export const LANDLORD_CALL_NUMBER = '+44 20 3936 9698'; // 9698 should be the ONLY landlord number in the end, others change
